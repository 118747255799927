import {writable} from "svelte/store";
import {Currency} from "@depay/local-currency";
import {
    default_currency,
    default_store_section, get_config,
    get_store_packs_route,
} from "./constants";

function getCookie(cookieName) {
    const cookieArr = document.cookie.split(";");
    for (let i = 0; i < cookieArr.length; i++) {
        const cookiePair = cookieArr[i].split("=");
        if (cookieName === cookiePair[0].trim()) {
            return decodeURIComponent(cookiePair[1]);
        }
    }
    return null;
}

export const user = writable(
    localStorage.user ? JSON.parse(localStorage.getItem("user")) : null
);

if (getCookie("connect.sid") == null) {
    user.set(null);
    localStorage.removeItem("user");
}

export const storePacks = writable([]);
export const subscriptions = writable([]);
export const gameConfig = writable({});
export const systemMessages = writable({});
export const rechargeRewardsEvent = writable([]);
export const luckyDrawEvent = writable([]);
export const selectedCurrency = writable(
    localStorage.currency ? localStorage.getItem("currency") : null
);
export const storeSection = writable(
    localStorage.storeSection
        ? localStorage.getItem("storeSection")
        : default_store_section
);
export const isRedirected = writable(false);

export const getStorePacks = () => {
    return fetch(get_store_packs_route, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
    })
        .then((response) => response.json())
        .then((data) => {
            // console.log(data);
            const heroEquipment = {};

            // for (var key in data.heroEquipment) {
            //     for (var jkey in data.heroEquipment[key]) {
            //         heroEquipment[jkey] = data.heroEquipment[key][jkey];
            //     }
            // }
            if (data.statusCode === 500) {
                Rollbar.error("Get packs status code 500 " + data.message);
            }

            if (data.statusCode === 403) {
                user.set(null);
                localStorage.removeItem("user");
                return;
            }

            gameConfig.set({
                gameItems: data.gameItems,
                commanders: data.commanders,
                heroEquipment: heroEquipment,
            });
            storePacks.set(data.packs);
            if (data.subscriptions !== undefined) {
                subscriptions.set(data.subscriptions);
            }
            if (data.systemMessages !== undefined) {
                systemMessages.set(data.systemMessages);
            }
            if (data.rechargeRewardsEvent !== undefined) {
                rechargeRewardsEvent.set(data.rechargeRewardsEvent);
            }
            if (data.luckyDrawEvent !== undefined) {
                luckyDrawEvent.set(data.luckyDrawEvent);
            }
            if (localStorage.getItem("currency") === null) {
                if (data.packs[0].webshopPrices[Currency.getCode()] === undefined) {
                    selectedCurrency.set(default_currency);
                } else {
                    selectedCurrency.set(Currency.getCode());
                }
            }

            castleLevel.set(data.castleLevel);
            vipLevel.set(data.vipLevel);
            maxWebshopDiscount.set(data.maxWebshopDiscount);
        })
        .catch((error) => Rollbar.error("Get packs error " + error));
};
export const giftFriend = writable({
    startPurchase: false,
    selectedPack: {},
    selectedPackDetails: {},
    message: '',
});
export const getConfig = () => {
    return fetch(get_config, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
    })
        .then((response) => response.json())
        .then((data) => {
            config.set(data);
            console.log('Config: ', data);
        })
        .catch((error) => Rollbar.error("Get config error " + error));
};

export const castleLevel = writable(0);
export const vipLevel = writable(0);
export const maxWebshopDiscount = writable(0);
export const config = writable({});
