<script>
    import {_} from 'svelte-i18n';
    import {onMount} from "svelte";
    import {getAnalytics, logEvent} from 'firebase/analytics';
    import {logMetric} from "../../metrics";
    import {Button, Card} from 'svelte-chota';
    import '../../jquery.min.js';
    import '../../TweenMax.js';
    import '../../_tweenMax.min.js';
    import '../../_jquery.spritely.js'
    import {getStorePacks, luckyDrawEvent, storePacks} from "../../stores.js";
    import Fa from 'svelte-fa/src/fa.svelte';
    import {faXmark} from '@fortawesome/free-solid-svg-icons';
    import {spin_for_discount_route} from "../../constants.js";

    export let lucky_draw_modal_hide;
    export let lucky_draw_packs_show;

    let jq = jQuery.noConflict();

    const analytics = getAnalytics();

    async function spinWheel() {

        try {
            const response = await fetch(spin_for_discount_route, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    eventId: $luckyDrawEvent.id,
                }),
            });

            if (!response.ok) {
                const message = `An error has occured: ${response.status}`;
                throw new Error(message);
            }

            const res = await response.json();

            setTimeout(() => {
                if (res.availableDiscount) {
                    erepublik.wheel_of_fortune.multispin($luckyDrawEvent.wheelEntries, 1, res, 1);

                    // Update the luckyDrawEvent with the availableDiscount from the response
                    luckyDrawEvent.update(currentEvent => {
                        return {...currentEvent, availableDiscount: res.availableDiscount};
                    });

                    getStorePacks();

                    // Use the update method to first remove packs with luckyDraw set to true, then add new packs
                    /* Method no longer used if paymentHub is used
                    storePacks.update(currentPacks => {
                        // Filter out packs with luckyDraw set to true
                        const filteredPacks = currentPacks.filter(pack => !pack.luckyDraw);

                        // Concatenate the filtered packs with the new packs
                        // Make sure to check if res.packs is an array to avoid errors
                        return Array.isArray(res.packs) ? filteredPacks.concat(res.packs) : filteredPacks;
                    });
                    */
                }
            }, 50);
        } catch (error) {
            Rollbar.error("Error spinning wheel " + error)
        } finally {
            logMetric({button: 'spin-lucky-draw'});
        }
    }

    let buildData, global_wof_build_data;

    buildData = global_wof_build_data = {
        "prizes": $luckyDrawEvent.wheelEntries,
    };

    // Define erepublik namespace if it doesn't exist
    var erepublik = erepublik || {};

    // Extend erepublik global object with wheel_of_fortune
    let obj;
    jq.extend(erepublik, obj = {
        wheel_of_fortune: {

            settings: {
                multiSpinIndex: 0
            },

            init: function (buildData) {

                //unlock the spin button if spin is available
                if (!buildData.error) {
                    jq('#wheelOfFortune').removeClass('locked');
                } else {
                    if (buildData.message === 'INSUFFICIENT_FUNDS') {
                        erepublik.wheel_of_fortune.showMessageBox('Insufficient funds.');
                        setTimeout(function () {
                            erepublik.wheel_of_fortune.hideMessageBox();
                            jq('#wheelOfFortune').removeClass('locked');
                        }, 3000);
                    } else {
                        erepublik.wheel_of_fortune.showMessageBox('Error! Please refresh if you believe this is not supposed to happen.');
                        setTimeout(function () {
                            erepublik.wheel_of_fortune.hideMessageBox();
                            jq('#wheelOfFortune').removeClass('locked');
                        }, 3000);
                    }
                }

                if (buildData.theme) {
                    jq('#wheelOfFortune').addClass(buildData.theme);
                }

                // Update buttons with multispin data
                if (buildData.multispin) {
                    var inc = 0;
                    Object.keys(buildData.multispin).forEach(function (key) {
                        var button = jq('#wheelOfFortune .wof_btn');
                        button.each(function (i) {
                            if (i === inc) {
                                jq(this).attr('data-multispin', key);
                                jq(this).find('.btn_title').html(buildData.multispin[key].spins + 'x');
                            }
                        });
                        inc++;
                    });
                }

                var i, len, lenPrizes, anchor, reward, elem, rewardTitle;

                //set tooltips for wheel prize icons
                lenPrizes = Object.keys(buildData.prizes).length;
                var size = 112, angle, rotate, css, radius = 160;
                anchor = jq('#wheelOfFortune > div.wof_wheel');


                for (var key in buildData.prizes) {
                    if (!buildData.prizes.hasOwnProperty(key)) {
                        continue;
                    }
                    reward = buildData.prizes[key];
                    elem = anchor.find('>.wof_prize_tooltip.prize_' + key);
                    if (!elem.length) {
                        angle = (360 / lenPrizes) * (Number(key));
                        css = {
                            transform: 'rotate(-' + angle + 'deg)',
                            height: size + 'px',
                            width: size + 'px',
                            top: 'calc((50% - ' + (size / 2) + 'px) - ' + ((size / 2) + radius) + 'px)',
                            left: 'calc(50% - ' + size / 2 + 'px)',
                            'transform-origin': (size / 2) + 'px ' + (radius + (size)) + 'px'
                        };

                        elem = jq('<span class="wof_prize_tooltip prize_' + key + '"><img></span>');
                        elem.css(css).appendTo(anchor);

                        erepublik.wheel_of_fortune.createPrizes(reward, elem);
                    }
                    elem.addClass(reward.type).attr('title', reward.tooltip);
                }

                jq('#wheelOfFortune, #wheelOfFortune > div.wof_wheel')
                    .addClass('numRewards_' + lenPrizes)
                    .data('num-prizes', lenPrizes);
            },

            multispin: function (items, items_steps, spinData, spins) {
                var that = this;

                jq('.wof_multi_prizes_overlay').hide();
                jq(".wof_multi_prizes_display").empty();
                jq(".wof_pop").children().not(".wof_multi_prizes_overlay").css('pointer-events', "auto");

                if (spinData.error) {
                    if (spinData.message === 'INSUFFICIENT_FUNDS') {
                        erepublik.wheel_of_fortune.showMessageBox('Insufficient funds.');
                        setTimeout(function () {
                            erepublik.wheel_of_fortune.hideMessageBox();
                            jq('#wheelOfFortune').removeClass('locked');
                        }, 3000);
                    } else {
                        erepublik.wheel_of_fortune.showMessageBox('Error! Please refresh if you believe this wasn\'t supposed to happen.');
                        setTimeout(function () {
                            erepublik.wheel_of_fortune.hideMessageBox();
                            jq('#wheelOfFortune').removeClass('locked');
                        }, 3000);
                    }
                    return false;
                }

                var thisEl, wheelTimeline, smallWheelTimeline, mediumWheelTimeline, wheelPin, rotateValue,
                    previousAngle,
                    prizeStarsTL, wheelDuration, mediumWheel, smallWheel, wheel_pin;
                //custom 'this' selector
                thisEl = jq('#wheelOfFortune .wof_wheel');
                mediumWheel = jq('#wheelOfFortune .wof_medium_wheel');
                smallWheel = jq('#wheelOfFortune .wof_small_wheel');
                wheel_pin = jq('#wheelOfFortune .wof_pin');

                mediumWheel.show();
                smallWheel.show();

                switch (spins) {
                    case 1:
                        wheelDuration = 6;
                        break;
                    case 5:
                        wheelDuration = 3;
                        break;
                    case 10:
                        wheelDuration = 1;
                        break;
                }

                //GSock Timelines for the wheels
                wheelTimeline = new TimelineMax();
                smallWheelTimeline = new TimelineMax();
                mediumWheelTimeline = new TimelineMax();
                wheelPin = new TimelineMax();

                //if prize is already shown, hide it each start of spin
                if (jq('#wheelOfFortune .wof_prize').hasClass('show') && this.settings.multiSpinIndex === 0) {
                    jq('#wheelOfFortune .wof_prize').removeClass('show animated tada');
                    jq('#wheelOfFortune .wof_prize_glow').removeClass('show animate');
                    jq('#wheelOfFortune .wof_prize_title').removeClass('show');
                    jq('.wof_prize_glow_stars_1, .wof_prize_glow_stars_2').removeClass('show');
                }

                //calculate final angle
                rotateValue = (360 * 5) + (360 / items.length) * erepublik.wheel_of_fortune.findPositionInItems(spinData.availableDiscount, items);

                //calculate initial angle from which it started
                previousAngle = erepublik.wheel_of_fortune.getRotationDegrees(thisEl);

                jq('#wheelOfFortune .wof_title').sprite({
                    fps: 8,
                    no_of_frames: 2,
                    play_frames: 16
                });

                //animate wheel
                wheelTimeline.to(thisEl, 0, {
                    rotation: previousAngle
                }).to(thisEl, wheelDuration, {
                    rotation: rotateValue,
                    ease: Expo.easeOut,
                    onComplete: function () {
                        erepublik.wheel_of_fortune.showPrize(spinData.availableDiscount);
                    }
                });

                //animate small wheel
                smallWheelTimeline.to(smallWheel, 0, {
                    rotation: previousAngle
                }).to(smallWheel, wheelDuration, {
                    rotation: -rotateValue,
                    ease: Expo.easeOut
                });

                //animate medium wheel
                mediumWheelTimeline.to(mediumWheel, 0, {
                    rotation: previousAngle
                }).to(mediumWheel, wheelDuration, {
                    rotation: rotateValue,
                    ease: Expo.easeOut
                });

                //animate pin
                wheelPin.to(wheel_pin, 0.25, {
                    rotation: 45,
                    transformOrigin: "50% 70%",
                    repeat: 5,
                    yoyo: true,
                }).to(wheel_pin, wheelDuration * 2, {
                    rotation: 0,
                    transformOrigin: "50% 70%",
                    ease: Expo.easeOut
                }, 1);
            },

            showPrize: function (multispinPrize) {

                var prize = jq('#wheelOfFortune .wof_prize');
                var prizeGlow = jq('#wheelOfFortune .wof_prize_glow');

                if (global_wof_build_data) {
                    if (multispinPrize) {
                        prize.attr({
                            'class': 'wof_prize percent_' + multispinPrize + ' show'
                        });
                    } else {
                        prize.attr({
                            'class': 'wof_prize percent_' + multispinPrize + ' show'
                        });
                    }

                    //show prize glow
                    prizeGlow.addClass('show');
                    jq('.wof_prize_glow_stars_1, .wof_prize_glow_stars_2').addClass('show');

                    setTimeout(function () {
                        prize.addClass('animated tada');
                    }, 100);

                    setTimeout(function () {
                        prizeGlow.addClass('animate');
                    }, 300);

                    setTimeout(function () {
                        lucky_draw_modal_hide(false, false);
                        lucky_draw_packs_show();
                    }, 3000);
                }
            },

            getRotationDegrees: function (selector) {

                //returns the CSS3 rotation degree value from an element
                var matrix = selector.css("-webkit-transform") ||
                    selector.css("-moz-transform") ||
                    selector.css("-ms-transform") ||
                    selector.css("-o-transform") ||
                    selector.css("transform");
                var angle;

                if (matrix !== 'none') {
                    var values = matrix.split('(')[1].split(')')[0].split(',');
                    var a = values[0];
                    var b = values[1];
                    angle = Math.round(Math.atan2(b, a) * (180 / Math.PI));
                } else {
                    angle = 0;
                }

                return (angle < 0) ? angle + 360 : angle;

            },

            showMessageBox: function (message) {
                jq('#wheelOfFortune .message_box').addClass('show').find('span').html(message);
            },

            hideMessageBox: function (message) {
                jq('#wheelOfFortune .message_box').removeClass('show');
            },

            createPrizes: function (reward, elem) {
                var imgsrc = '/images/lucky_draw/prizes';
                elem.find('img').attr('src', imgsrc += '/percent_' + reward + '.png');
            },

            findPositionInItems: function (element, items) {
                let elementAsString = String(element);
                return items.indexOf(elementAsString);
            }
        }
    });

    function addEventListener() {
        const background = document.querySelector('.background');
        if (background) {
            background.addEventListener('click', lucky_draw_modal_hide);
        }
    }

    onMount(async () => {
        await logMetric({show_lucky_draw_modal: "true"});

        logEvent(analytics, 'screen_view', {
            firebase_screen: 'lucky_draw_modal',
            firebase_screen_class: 'web',
        });
        erepublik.wheel_of_fortune.init(buildData);

        jq('#wheelOfFortune').show();
        jq('#wheelOfFortune .wof_title').sprite({
            fps: 8,
            no_of_frames: 2,
            play_frames: 16
        });
        jq('#loader').hide();

        addEventListener();
    });
</script>

<Card class="lucky-draw-modal">
    <div class="wof_pop locked
        {$luckyDrawEvent.specialEvent === 12 ? 'valentines' : ''}
        {$luckyDrawEvent.specialEvent === 6 ? 'easter' : ''}
        " id="wheelOfFortune">
        <a class="close-button" href="javascript:" on:click={lucky_draw_modal_hide}>
            <Fa fw icon={faXmark} primaryColor="#666" size="3x"/>
        </a>
        <div class="wof_overlay"></div>
        <div class="message_box"><span></span></div>
        <div class="wof_title"></div>
        <div class="wof_pin"></div>
        <div class="wof_small_wheel"></div>
        <div class="wof_medium_wheel"></div>
        <div class="wof_pin_holder"></div>
        <div class="wof_wheel">
        </div>
        <span class="wof_prize_title"></span>
        <div class="wof_prize_glow"></div>
        <div class="wof_prize_glow_stars_1"></div>
        <div class="wof_prize_glow_stars_2"></div>
        <div class="wof_prize"></div>
        <a class="wof_btn" href="javascript:">
            <Button
                    class="orange"
                    primary
                    on:click={spinWheel}
            >
                {$_('SpinTheWheel')}
            </Button>
        </a>
    </div>
</Card>

<style lang="scss">
  @import '../../styles/vars.scss';

  :global {
    .lucky-draw-modal {
      &.card {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s ease-in;
        position: relative;
        background: none !important;
        box-shadow: none !important;
        -webkit-box-shadow: none;
        padding: 0;
      }

      @keyframes rotate-one {
        0% {
          transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
        }
        100% {
          transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
        }
      }

      @keyframes rotate-two {
        0% {
          transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
        }
        100% {
          transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
        }
      }

      @keyframes rotate-three {
        0% {
          transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
        }
        100% {
          transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
        }
      }

      @keyframes sparkle_fade_out_in {
        0% {
          opacity: 1;
        }
        45% {
          opacity: 0;
        }
        90% {
          opacity: 1;
        }
        100% {
          opacity: 1;
        }
      }

      @keyframes sparkle_fade_in_out {
        0% {
          opacity: 0;
        }
        45% {
          opacity: 1;
        }
        90% {
          opacity: 0;
        }
        100% {
          opacity: 0;
        }
      };

      @keyframes light_fade_out_in {
        0% {
          opacity: 1;
        }

        50% {
          opacity: 0.3;
        }

        100% {
          opacity: 1;
        }
      };

      @keyframes rightRotation {
        0% {
          -webkit-transform: rotate(0);
          -ms-transform: rotate(0);
          transform: rotate(0);
        }

        100% {
          -webkit-transform: rotate(360deg);
          -ms-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }

      @keyframes leftRotation {
        0% {
          -webkit-transform: rotate(0);
          -ms-transform: rotate(0);
          transform: rotate(0);
        }

        100% {
          -webkit-transform: rotate(-360deg);
          -ms-transform: rotate(-360deg);
          transform: rotate(-360deg);
        }
      }

      #wheelOfFortune {
        background: url("/images/lucky_draw/popup_bg.png") no-repeat 0 0;
        display: block;
        width: 1848px;
        height: 1846px;
        border-radius: 5px;

        &.valentines {
          background: url("/images/lucky_draw/popup_bg_valentines.png") no-repeat 0 0;
        }

        &.easter {
          background: url("/images/lucky_draw/popup_bg_easter.png") no-repeat 0 0;
        }

        @media screen and (min-width: 250px) {
          transform: scale(0.23);
        }

        @media screen and (min-width: 320px) {
          transform: scale(0.28);
        }

        @media screen and (min-width: 375px) {
          transform: scale(0.33);
        }

        @media screen and (min-width: 425px) {
          transform: scale(0.38);
        }

        @media screen and (min-width: 768px) {
          transform: scale(0.43);
        }

        @media screen and (min-width: 1024px) {
          transform: scale(0.48);
        }

        @media screen and (min-width: 2560px) {
          transform: scale(0.53);
        }

        .close-button {
          position: absolute;
          top: 495px;
          right: 470px;
          z-index: 12;
        }

        .wof_title {
          background: url("/images/lucky_draw/powerspin_title_sprite.png") no-repeat 0 0;
          width: 614px;
          height: 195px;
          margin: 0 auto;
          position: absolute;
          top: 523px;
          right: 7px;
          left: 0;
        }

        .wof_pin {
          background: url("/images/lucky_draw/wheel_pin.png") no-repeat 0 0;
          width: 102px;
          height: 183px;
          position: absolute;
          top: 800px;
          left: 870px;
          z-index: 9;
        }

        .wof_wheel {
          background: url("/images/lucky_draw/popup_wheel-empty.png") no-repeat 0 0;
          width: 582px;
          height: 580px;
          position: absolute;
          top: 640px;
          left: 630px;

          .wof_prize_tooltip {
            display: block;
            width: 112px;
            height: 112px;
            position: absolute;
          }

          span {
            img {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translateY(-50%) translateX(-50%);
              max-height: 100%;
              max-width: 100%;
            }
          }
        }

        .wof_prize_title {
          color: #d4d6d6;
          font: normal bold 11px / 12px var(--font-family-noto-sans);
          margin: auto;
          opacity: 0;
          position: absolute;
          top: 220px;
          right: 0;
          left: 0;
          text-align: center;
          text-shadow: 0 0 1px #000;
          text-transform: uppercase;
          width: 120px;
          transform: scale(0);
          transition: all 0.35s;

          &.show {
            opacity: 1;
            top: 170px;
            transform: scale(1);
          }
        }

        .wof_prize {
          position: absolute;
          filter: drop-shadow(0px 0px 0.1vh white) drop-shadow(0px 0px 1vh white);
          transform: scale(0);
          transition: transform 0.35s;
          height: 224px;
          width: 224px;
          top: 800px;
          left: 805px;
          z-index: 12;

          &.percent_0 {
            background: url("/images/lucky_draw/prizes/percent_0.png") no-repeat 0 0;
          }

          &.percent_5 {
            background: url("/images/lucky_draw/prizes/percent_5.png") no-repeat 0 0;
          }

          &.percent_10 {
            background: url("/images/lucky_draw/prizes/percent_10.png") no-repeat 0 0;
          }

          &.percent_15 {
            background: url("/images/lucky_draw/prizes/percent_15.png") no-repeat 0 0;
          }

          &.percent_25 {
            background: url("/images/lucky_draw/prizes/percent_25.png") no-repeat 0 0;
          }

          &.percent_35 {
            background: url("/images/lucky_draw/prizes/percent_35.png") no-repeat 0 0;
          }

          &.percent_40 {
            background: url("/images/lucky_draw/prizes/percent_40.png") no-repeat 0 0;
          }

          &.percent_50 {
            background: url("/images/lucky_draw/prizes/percent_50.png") no-repeat 0 0;
          }

          &.percent_60 {
            background: url("/images/lucky_draw/prizes/percent_60.png") no-repeat 0 0;
          }

          &.percent_70 {
            background: url("/images/lucky_draw/prizes/percent_70.png") no-repeat 0 0;
          }

          &.percent_80 {
            background: url("/images/lucky_draw/prizes/percent_80.png") no-repeat 0 0;
          }

          &.percent_90 {
            background: url("/images/lucky_draw/prizes/percent_90.png") no-repeat 0 0;
          }

          &.show {
            transform: scale(1);
          }
        }

        .wof_pin_holder {
          background: url("/images/lucky_draw/stationary_wheelpart.png") no-repeat 0 0;
          width: 200px;
          height: 199px;
          position: absolute;
          bottom: 815px;
          left: 820px;
          z-index: 8;
        }

        .wof_small_wheel {
          background: url("/images/lucky_draw/wheel_small_size.png") no-repeat 0 0;
          width: 1274px;
          height: 1271px;
          position: absolute;
          bottom: 270px;
          left: 270px;
          z-index: -1;
          display: none;
        }

        .wof_medium_wheel {
          background: url("/images/lucky_draw/wheel_medium_size.png") no-repeat 0 0;
          width: 1848px;
          height: 1846px;
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: -1;
          display: none;
        }

        .wof_prize_glow {
          background: url("/images/lucky_draw/prize_glow.png") no-repeat 0 0;
          display: block;
          width: 1371px;
          height: 1369px;
          opacity: 0;
          position: absolute;
          top: 225px;
          left: 230px;
          transition: opacity 1s;
          z-index: 10;

          &.animate {
            animation: rightRotation 15s infinite;
            animation-timing-function: linear;
          }

          &.show {
            opacity: 1;
          }
        }

        .wof_prize_glow_stars_1 {
          background: url("/images/lucky_draw/prize_glow_stars_1.png") no-repeat 0 0;
          display: block;
          width: 834px;
          height: 831px;
          position: absolute;
          top: 500px;
          left: 500px;
          transform: scale(0);
          transition: transform 0.2s;
          z-index: 11;

          &.show {
            animation: leftRotation 10s infinite;
            animation-timing-function: linear;
            transform: scale(1) translateZ(0);
            backface-visibility: hidden;
            perspective: 1000px;
          }
        }

        .wof_prize_glow_stars_2 {
          background: url("/images/lucky_draw/prize_glow_stars_2.png") no-repeat 0 0;
          display: block;
          width: 1173px;
          height: 1170px;
          position: absolute;
          top: 350px;
          left: 350px;
          transform: scale(0);
          transition: transform 0.2s;
          z-index: 11;

          &.show {
            animation: rightRotation 10s infinite;
            animation-timing-function: linear;
            transform: scale(1) translateZ(0);
            backface-visibility: hidden;
            perspective: 1000px;
          }
        }

        .wof_btn {
          height: 85px;
          margin: 0 auto;
          position: absolute;
          right: 0;
          bottom: 461px;
          left: 2px;
          text-align: center;
          width: 330px;
          z-index: 100;

          button {
            height: 85px;
            font-size: 30px;
          }
        }
      }
    }
  }
</style>