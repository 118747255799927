<script>
    import {_, isLoading, locale, locales} from 'svelte-i18n';
    import {setContext} from 'svelte';
    import Fa from 'svelte-fa/src/fa.svelte';
    import Checkout from './lib/Checkout.svelte';
    import SystemMessage from './lib/SystemMessage.svelte';
    import {faGlobe, faLock, faUser, faWallet} from '@fortawesome/free-solid-svg-icons';
    import {logEvent, setUserProperties,} from 'firebase/analytics';
    import Loader from './lib/Loader.svelte';
    import VanishingHeader from './lib/VanishingHeader.svelte';
    import {
        getStorePacks,
        getConfig,
        selectedCurrency,
        storePacks,
        storeSection,
        systemMessages,
        rechargeRewardsEvent,
        user,
        castleLevel, luckyDrawEvent, vipLevel, maxWebshopDiscount
    } from './stores';
    import {Modal, Tab, Tabs} from 'svelte-chota';
    import Promos from './lib/views/Promos.svelte';
    import DailyDeals from './lib/views/DailyDeals.svelte';
    import Subscriptions from './lib/views/Subscriptions.svelte';
    import Settings from "./lib/Settings.svelte";
    import GoldRush from "./lib/views/GoldRush.svelte";
    import Gold from './lib/views/Gold.svelte';
    import {logout_route} from './constants';
    import {analytics, logMetric} from './metrics';
    import Resources from "./lib/views/Resources.svelte";
    import {subscriptions} from "./stores.js";
    import RedeemCode from "./lib/views/RedeemCode.svelte";
    import GiftFriend from "./lib/views/GiftFriend.svelte";
    import Ribbon from "./lib/Ribbon.svelte";
    import LuckyDraw from "./lib/views/LuckyDraw.svelte";
    import BeginnerPacks from "./lib/views/BeginnerPacks.svelte";
    import WelcomeBackPacks from "./lib/views/WelcomeBackPacks.svelte";
    import MidweekMadness from "./lib/views/MidweekMadness.svelte";
    import Vip from "./lib/views/Vip.svelte";
    import ResourceTrove from "./lib/views/ResourceTrove.svelte";
    import ScrollableNavbar from "./lib/ScrollableNavbar.svelte";
    import Featured from "./lib/views/Featured.svelte";
    import WebshopExclusive from "./lib/views/WebshopExclusive.svelte";
    import Notifications from "./lib/Notifications.svelte";

    let _user;
    let _systemMessages;
    let getPacks;

    user.subscribe((data) => {
        if (data === null) return;

        setUserProperties(analytics, {
            username: data.user.username,
            id: data.user.id,
        });

        _user = data;
        getPacks = getStorePacks();
        getConfig();
    });

    selectedCurrency.subscribe((data) => {
        if (data === null) return;

        setUserProperties(analytics, {
            currency: data,
        });

        logMetric({currency: data});

        localStorage.setItem('currency', data);
    });

    storeSection.subscribe((data) => {
        if (data === null) return;

        logMetric({screen_view: data});

        logEvent(analytics, 'screen_view', {
            firebase_screen: data,
            firebase_screen_class: 'web',
        });

        localStorage.setItem('storeSection', data);
    });

    const logout = () => {
        logMetric({button: 'logout'});

        fetch(logout_route, {
            method: 'GET',
        })
            .then(() => {
                localStorage.removeItem('user');
                localStorage.removeItem('storeSection');
                user.set(null);
            })
            .catch((error) => Rollbar.error("Logout error:" + error));
    };

    locale.subscribe((data) => {
        if (data === null) return;

        logMetric({language: data});

        setUserProperties(analytics, {
            language: data,
        });
    });

    function redirectToDailyDeals() {
        // Select all the span elements within the element with class "tabs"
        let spans = document.querySelectorAll('.tabs span');

        // Loop through the span elements
        for (let i = 0; i < spans.length; i++) {
            let span = spans[i];

            // Example based on text content:
            if (span.textContent.includes($_('NewShopTabDailyDeals'))) {
                // Simulate a click on the span
                span.click();
                break;
            }
        }
    }

    function checkGridChildren() {
        const gridElement = document.querySelector('.grid');
        const isEmpty = !gridElement || gridElement.children.length === 0;

        if (isEmpty
            && localStorage.getItem("storeSection") !== 'luckyDraw'
            && localStorage.getItem("storeSection") !== 'goldRush'
            && localStorage.getItem("storeSection") !== 'redeemCode'
            && localStorage.getItem("storeSection") !== 'giftFriend') {
            redirectToDailyDeals();
        }
    }

    storePacks.subscribe((data) => {
        if (data.length === 0) return;

        setTimeout(() => {
            checkGridChildren();
        }, 1000);

        if (localStorage.getItem("storeSection") === 'goldRush' && Object.keys($rechargeRewardsEvent).length === 0) {
            setTimeout(() => {
                redirectToDailyDeals();
            }, 1000);
        }

        if (localStorage.getItem("storeSection") === 'luckyDraw' && $storePacks.filter((pack) => pack.luckyDraw).length <= 0) {
            setTimeout(() => {
                redirectToDailyDeals();
            }, 1000);
        }

        if (localStorage.getItem("storeSection") === 'vip') {
            // Filter packs that are in the 'vip' section
            const vipPacks = $storePacks.filter(pack => pack.storeSection === 'vip');

            // Check if among these vipPacks, none match the regex in their bundleName
            const hasMatchingVipPack = vipPacks.some(pack => {
                const matchResult = pack.bundleName.match(/lvl(\d+)pack/);
                return matchResult !== null && matchResult[1] <= $vipLevel;
            });

            // If there is no matching vip pack, redirect/set to 'dailyDeals'
            if (!hasMatchingVipPack) {
                redirectToDailyDeals();
            }
        }

        if (localStorage.getItem("storeSection") !== 'promos') return;

        if (data.filter((pack) => pack.storeSection === 'promos').length <= 0) {
            redirectToDailyDeals();
        }
    });

    let storePack = null;
    let packDetails = null;

    setContext('buyPack', {buyPack});

    export function buyPack(event) {
        storePack = event.detail.storePack;
        packDetails = event.detail.packDetails;
        select_payment_modal_open = true;
    }

    let select_payment_modal_open = false;
    const payments_modal_show = () => (select_payment_modal_open = true);
    export let payments_modal_hide = () => {
        select_payment_modal_open = false;
    };

    let system_message_modal_open = false;
    const system_message_modal_show = () => (system_message_modal_open = true);
    export let system_message_modal_hide = () => {
        system_message_modal_open = false;
    };

    systemMessages.subscribe((data) => {
            if (data && Object.keys(data).length === 0 && Object.getPrototypeOf(data) === Object.prototype) return;
            _systemMessages = data;

            setTimeout(() => {
                system_message_modal_show();
            }, 1000);
        }
    );

    let settings_modal_open = false;
    const settings_modal_show = event => settings_modal_open = true;
    export let settings_modal_hide = event => settings_modal_open = false;

    const languageNames = {
        en: 'English',
        es: 'Español',
        fr: 'Français',
        de: 'Deutsch',
        it: 'Italiano',
        ja: '日本語',
        ko: '한국어',
        pt: 'Português',
        ru: 'русский',
        zh: '中文',
        tr: 'Türkçe',
    };

    let activeSubTab = 'subscriptions';

    function changeSubTab(tab) {
        activeSubTab = tab;
    }
</script>

{#if user && _user}
    {#if $storePacks.length === 0}
        <Loader/>
    {/if}
    {#if $isLoading}
        <Loader/>
    {:else}
        <Notifications/>
        {#await getPacks then value}
            <VanishingHeader offset={50} tolerance={10}>
                <div class="user-area">
                    <h1>
                        {$_('OnboardingStep1Text1_2019')}
                    </h1>
                    <div class="user-controls themed">
                        <div class="user-select">
                            <Fa icon={faGlobe} fw primaryColor="gold"/>
                            <select bind:value={$locale} class="selector">
                                {#each $locales as locale}
                                    <option value={locale} title={languageNames[locale]}
                                    >{locale.toUpperCase()}</option
                                    >
                                {/each}
                            </select>
                        </div>

                        <!--                        <div class="user-select">-->
                        <!--                            <a title="{$_('Settings')}" on:click={settings_modal_show} href="javascript:">-->
                        <!--                                <Fa icon={faGear} fw primaryColor="#gold"/>-->
                        <!--                            </a>-->
                        <!--                        </div>-->
                    </div>
                    <div class="user-info">
                        <b>
                            <Fa icon={faUser} fw primaryColor="gold"/>{_user?.user.username}
                        </b>
                        <a on:click={logout} href="#logout" class="">{$_('Logout')}</a>
                    </div>
                </div>
                <ScrollableNavbar/>
            </VanishingHeader>
            {#if $storeSection !== "redeemCode" && $storeSection !== "giftFriend" && $storeSection !== "luckyDraw"}
                {#if $storeSection === 'goldAndResources'}
                    <div class="tabs submenu">
                        <span
                                class:active={activeSubTab === 'subscriptions'}
                                on:click={() => changeSubTab('subscriptions')}>
                            {$_('NewShopTabSubscriptions')}
                        </span>
                        <span
                                class:active={activeSubTab === 'resources'}
                                on:click={() => changeSubTab('resources')}>
                            {$_('NewShopTabResources')}
                        </span>
                        <span
                                class:active={activeSubTab === 'gold'}
                                on:click={() => changeSubTab('gold')}>
                            {$_('NewShopTabGoldPacks')}
                        </span>
                    </div>
                {/if}
                <div class="discount-banner">
                    <h3 class="animate__animated animate__shakeY animate__delay-2s">Up to
                        <span>{$maxWebshopDiscount}%</span>
                        discount in this
                        shop!
                    </h3>
                    <h4 class=""><span>{$maxWebshopDiscount}%</span>
                        <strong>discount</strong></h4>
                </div>
            {/if}
            <section class="pack-listing">
                {#if $storeSection === 'promos'}
                    {#if $storePacks.filter((pack) => pack.storeSection === 'promos').length > 0}
                        <Promos/>
                    {/if}
                {:else if $storeSection === 'dailyDeals'}
                    <DailyDeals/>
                {:else if $storeSection === 'goldAndResources'}
                    {#if activeSubTab === 'subscriptions'}
                        {#if $storePacks.filter((pack) => pack.storeSection === 'subscriptions').length > 0 || $subscriptions.length > 0}
                            <Subscriptions/>
                        {/if}
                    {:else if activeSubTab === 'resources'}
                        <Resources/>
                    {:else if activeSubTab === 'gold'}
                        <Gold/>
                    {/if}
                {:else if $storeSection === 'goldRush' && Object.keys($rechargeRewardsEvent).length !== 0}
                    <GoldRush/>
                {:else if $storeSection === 'giftFriend'}
                    <GiftFriend/>
                {:else if $storeSection === 'redeemCode'}
                    <RedeemCode/>
                {:else if $storeSection === 'luckyDraw' && Object.keys($luckyDrawEvent).length !== 0}
                    <LuckyDraw/>
                {:else if $storeSection === 'beginnerPacks'}
                    <BeginnerPacks/>
                {:else if $storeSection === 'welcomeBackPacks'}
                    <WelcomeBackPacks/>
                {:else if $storeSection === 'midweekMadness'}
                    <MidweekMadness/>
                {:else if $storeSection === 'vip'}
                    <Vip/>
                {:else if $storeSection === 'resourceTrove'}
                    <ResourceTrove/>
                {:else if $storeSection === 'featured'}
                    <Featured/>
                {:else if $storeSection === 'webshopExclusive'}
                    <WebshopExclusive/>
                {/if}
            </section>
        {:catch error}
            <b>Error loading store packs. Please try again.</b>
        {/await}
    {/if}
    <Modal class="checkout" bind:open={select_payment_modal_open}>
        <Checkout {storePack} {packDetails} {payments_modal_hide}/>
    </Modal>
    <Modal class="system-message" bind:open={system_message_modal_open}>
        <SystemMessage {_systemMessages} {system_message_modal_hide}/>
    </Modal>
    <Modal class="settings-modal" bind:open={settings_modal_open}>
        <Settings {settings_modal_hide}/>
    </Modal>
{/if}

<style lang="scss">
  @import './styles/vars.scss';

  :global {
    .pin, .unpin {
      &.header {
        height: $headerHeight;
        z-index: 50;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .selectContainer {
        width: 80px;
      }

      .tabs .lock-icon {
        margin-right: 5px;
      }

      .tabs .ribbon-wrapper {
        width: 23px;
        height: 41px;
        transform: scale(0.8);
        left: 0;
        top: -21px;

        span {
          transform: rotate(0deg);
          left: -38px;
          line-height: 17px;
          width: 92px;
        }
      }
    }
  }

  .discount-banner {
    --banner-accent: #f6ff0080;
    --banner-main: rgb(188 38 235);
    --banner-main-darker: #152998;
    --banner-dark: #ca4afa;
    --border-width: 5px;
    height: 50px;
    width: auto;
    background: var(--banner-main);
    background: linear-gradient(145deg, var(--banner-main) 0%, var(--banner-main-darker) 100%);
    display: table;
    border-radius: var(--border-width);
    position: relative;
    box-shadow: 0 0 25px var(--banner-dark), 0 0 30px rgba(0, 0, 0, 0.2) inset;
    padding: 0 20px;
    margin: 0 auto;
    top: 135px;
    cursor: default;

    @media (max-width: 700px) {
      padding: 0 10px;
      height: 35px;
      top: 120px;
    }

    &.winter {
      &::after {
        position: absolute;
        content: "";
        top: calc(-1 * var(--border-width));
        left: calc(-1 * var(--border-width));
        z-index: -1;
        width: calc(100% + var(--border-width) * 2);
        height: calc(100% + var(--border-width) * 2);
        background: linear-gradient(
                        60deg,
                        hsl(0, 0%, 0%),
                        hsl(180, 78%, 58%),
                        hsl(0, 0%, 0%),
                        hsl(180, 78%, 58%),
                        hsl(0, 0%, 0%),
                        hsl(180, 78%, 58%),
                        hsl(0, 0%, 0%),
                        hsl(180, 78%, 58%)
        );
        background-size: 300% 300%;
        background-position: 0 50%;
        border-radius: calc(2 * var(--border-width));
        animation: moveGradient 5s alternate infinite;
        filter: blur(1px);
      }

      @keyframes moveGradient {
        50% {
          background-position: 100% 50%;
        }
      }
    }

    &::after {
      position: absolute;
      content: "";
      top: calc(-1 * var(--border-width));
      left: calc(-1 * var(--border-width));
      z-index: -1;
      width: calc(100% + var(--border-width) * 2);
      height: calc(100% + var(--border-width) * 2);
      background: linear-gradient(
                      60deg,
                      hsl(224, 85%, 66%),
                      hsl(269, 85%, 66%),
                      hsl(314, 85%, 66%),
                      hsl(359, 85%, 66%),
                      hsl(44, 85%, 66%),
                      hsl(89, 85%, 66%),
                      hsl(134, 85%, 66%),
                      hsl(179, 85%, 66%)
      );
      background-size: 300% 300%;
      background-position: 0 50%;
      border-radius: calc(2 * var(--border-width));
      animation: moveGradient 4s alternate infinite;
      filter: blur(1px);
    }

    @keyframes moveGradient {
      50% {
        background-position: 100% 50%;
      }
    }

    h3 {
      font-family: var(--font-family-sans-condensed);
      font-weight: bold;
      font-size: 3rem;
      font-variant: small-caps;
      padding-left: 35px;
      text-shadow: 4px 3px 0 rgba(0, 0, 0, 0.1);
      line-height: 50px;
      color: aliceblue;
      margin: 0;
      white-space: nowrap;

      @media (max-width: 700px) {
        font-size: 1.5rem;
        line-height: 35px;
      }

      @media (max-width: 300px) {
        font-size: 1rem;
      }

      span {
        color: yellow;
      }
    }


    h4 {
      background: rgb(251, 244, 63);
      background: linear-gradient(146deg, rgba(251, 244, 63, 1) 0%, rgba(252, 117, 70, 1) 100%);
      border-radius: 50%;
      display: inline-block;
      height: 86px;
      width: 86px;
      position: absolute;
      text-align: center;
      margin: 0;
      font-weight: 700;
      font-family: var(--font-family-sans-condensed);
      line-height: 22px;
      padding-top: 22px;
      letter-spacing: -0.5px;
      overflow: hidden;
      top: -18px;
      left: -40px;
      //outline: 5px solid var(--banner-dark);
      box-shadow: 0 4px 0 #301806, 0 0 20px rgba(0, 0, 0, 0.4), 0 0 30px rgba(0, 0, 0, 0.2) inset;
      transform: rotate(-15deg);

      @media (max-width: 700px) {
        transform: rotate(-15deg) scale(0.6);
        top: -25px;
        left: -29px;
      }

      span {
        font-size: 4rem;
        color: #4a2408;
        text-shadow: 0 2px 0 rgb(255 255 255 / 30%);
      }

      strong {
        text-transform: uppercase;
        font-size: 1.4rem;
        top: -1px;
        position: relative;
        background-color: rgba(0, 0, 0, 0.2);
        text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
        display: block;
        line-height: 3rem;
        color: #f6f8c2;
        padding-bottom: 20px;
      }
    }

  }

  .themed {
    --borderRadius: 5px;
    --height: 35px;
    --border: none !important;
    --margin: 0 10px 0 0;
  }

  .pack-listing {
    margin-top: $headerHeight + 60;
    min-height: 57vh;

    @media (max-width: 700px) {
      margin-top: $headerHeight + 35;
    }
  }

  .user-info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    line-height: 18px;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    padding-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-align: right;

    b {
      min-width: 80px;
    }
  }

  .user-area {
    height: 50px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 0 20px;
    display: flex;
    background: darken($dark-color, 1%);
    align-content: center;
    align-items: center;
    background: linear-gradient(180deg, $dark-color 0%, #000 100%);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.4);
    justify-content: space-between;

    @media (max-width: 700px) {
      justify-content: space-between;

      h1 {
        display: none;
        // font-size: 0.8em !important;
        // line-height: 1.1em;
      }
    }

    h1 {
      margin: 0;
      padding: 0;
      font-size: 16px;
      color: $light-color;
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.6);
    }

    .user-controls {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: auto;

      @media (max-width: 700px) {
        margin-left: 0;
      }

      .user-select {
        position: relative;
        top: 0;
        right: 0;
        margin-right: 10px;
      }
    }
  }

  .tabs-dropdown {
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
    color: #fff;
    font-family: var(--font-family-sans);
    background: $dark-color;
    text-align: center;
    font-weight: 700;
    border-radius: 5px;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+Cjxzdmcgdmlld0JveD0iMCAwIDI5Mi40IDI5Mi40IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogPGcgY2xhc3M9ImxheWVyIj4KICA8dGl0bGU+TGF5ZXIgMTwvdGl0bGU+CiAgPHBhdGggZD0ibTI4Nyw2OS40YTE3LjYsMTcuNiAwIDAgMCAtMTMsLTUuNGwtMjU1LjYsMGMtNSwwIC05LjMsMS44IC0xMi45LDUuNGExNy42LDE3LjYgMCAwIDAgLTUuNSwxMi44YzAsNSAxLjgsOS4zIDUuNCwxMi45bDEyOCwxMjcuOWMzLjYsMy42IDcuOCw1LjQgMTIuOCw1LjRzOS4yLC0xLjggMTIuOCwtNS40bDEyOCwtMTI4YzMuNSwtMy41IDUuNCwtNy44IDUuNCwtMTIuOGMwLC01IC0xLjksLTkuMiAtNS41LC0xMi44bDAuMSwweiIgZmlsbD0iI2ZmZmZmZiIgaWQ9InN2Z18xIi8+CiA8L2c+Cjwvc3ZnPg==');
    padding: 0px 30px 0px 25px;
    margin: 5px 3px;
    background-repeat: no-repeat, repeat;
    background-position: right 20px top 50%, 0 0;
    background-size: 1em auto, 100%;

    @media (max-width: 768px) {
      .tabs-dropdown {
        display: block;
      }
    }
  }
</style>
